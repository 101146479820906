import styled from "styled-components";

export const CenterDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
`

export const PrimaryColor = '#007192'
export const DangerColor = '#D63229'
export const SuccessColor = '#34C759'
export const CollectColor = '#187DE4'
export const DefaultColor = '#ECF0F3'
export const PreparedColor = '#FE7100'

export const FlexRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
` 

export const FinalMessage = styled.p`
    color: ${(props) => props.color};
    font-size: ${(props) => props.size};
    margin: 0;
    text-transform: capitalize;
`

export const NoDataFound = styled.p`
    color: ${PrimaryColor};
    font-size: 16px;
    text-align: center;
`