import React from 'react'
import { MobileTableStyledMain } from './StyledMobileTable';
import { CollectColor, DangerColor, SuccessColor } from "../../../CommonComponents/Global";
import CustomButton from '../../../CommonComponents/Button';

const MobileTable = ({ pendingData , handler }) => {
    return (
      <MobileTableStyledMain>
      <div className="inner-section container">
        {pendingData?.map((user, index) => (
          <div key={index} className="card">
            <div className="name-section d-flex justify-content-between">
              <p className="name">{user.fullName}</p>
              <p className="id">{user.cellNumber}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="details">{user.emailAddress}</p>
              <p className="details-1">{user.request}</p>
            </div>
            <div className="price-section d-flex justify-content-between">
              <p className="price">{user.status}</p>
            </div>
            <div className="actions-section d-flex  justify-content-center">
              <CustomButton
                bkColor={CollectColor}
                color="white"
                padding="2px"
                width="100%"
                type="button"
                title="COLLECT"
                clicked={() => handler(user)}
              />
            </div>
          </div>
        ))}
      </div>


      </MobileTableStyledMain>
    )
  }

  export default MobileTable;