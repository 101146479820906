import React, { useEffect, useState } from "react";
import SideBar from "../../../DashboardLayout/SideBar/Index";
import { UserDetailContainer } from "./style";
import { useParams } from "react-router-dom";
import GenericService from "../../../services/GenericService";
import { API_URL } from "../../../services/config";
import { PreparedColor , FlexRowContainer , CollectColor , DefaultColor } from "../../../CommonComponents/Global";
import CustomButton from "../../../CommonComponents/Button";
import ConfirmationModal from "../../../Components/ConfirmationModal/Index";
import { SunshineModalContent } from "../pending/style";
import { useNavigate } from "react-router-dom";
import LastConfirmationModal from "../../../Components/ConfirmationModal/LastConfirmationModal";


const Index = () => {
  const { id } = useParams();
  const genericService = new GenericService();
  const navigate = useNavigate();


  const [userDetail, setuserDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);


  useEffect(() => {
    const data = {
      id: id,
    };
    genericService
      .post(`${API_URL}getuserdata`, data)
      .then((msg) => {
        console.log(msg, "msgmsg");
        setuserDetail(msg?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);


  function preparedOrderHandler(user) {
    const data = {
      id: user.id,
      cellNumber: user.cellNumber,
      status: 6,
    };

    genericService
      .post(`${API_URL}updateorder`, data)
      .then((msg) => {
        setconfirmModal(true)
        setVisible(false);
        setTimeout(() => {
          navigate("/collect-order");
        },2000)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <SideBar>
      <UserDetailContainer>
      <LastConfirmationModal
          visible={confirmModal}
          setVisible={setconfirmModal}
        >
          <SunshineModalContent>
            {/* <h1>Update Successfully</h1>
            <p>
              {" "}
              Order is updated
              <span className="modal-content-variable">
                {" "}
                {userDetail.fullName}
              </span>{" "}
              Status to{" "}
              <span className="modal-content-variable">PREPARED ORDER</span>
            </p> */}
               <p> Your order
                <span className="modal-content-variable">{ ' '} {userDetail.fullName}</span> Status Update Successfully
              </p>
          </SunshineModalContent>
        </LastConfirmationModal>
      <ConfirmationModal visible={visible} setVisible={setVisible}>
          <SunshineModalContent>
            <h1>CONFIRMATION UPDATE STATUS</h1>
            <p>
              You want to update{" "}
              <span className="modal-content-variable">
                {userDetail.fullName}
              </span>{" "}
              Order Status to{" "}
              <span className="modal-content-variable">Prepared Order</span>
            </p>
            <div className="sunshine-modal-button">
              <FlexRowContainer>
                <CustomButton
                  bkColor={CollectColor}
                  color="white"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Yes"
                  clicked={() => preparedOrderHandler(userDetail)}
                />
                <CustomButton
                  bkColor={DefaultColor}
                  color="black"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Cancel"
                  clicked={() => setVisible(false)}
                />
              </FlexRowContainer>
            </div>
          </SunshineModalContent>
        </ConfirmationModal>
        <div className="userDetail-fields">
          <div className="userDetail-fields-one">
            <div>
              <label>First Name</label>
              <p>{userDetail.fullName}</p>
            </div>

            <div>
              <label>Phone</label>
              <p>{userDetail.cellNumber}</p>
            </div>
          </div>
          <div className="userDetail-fields-two">
            <div>
              <label>Email</label>
              <p>{userDetail.emailAddress}</p>
            </div>

            <div>
              <label>Pickup Request Time</label>
              <p>{userDetail.orderTime} , {userDetail.orderDate}</p>
            </div>
          </div>
          <div className="userDetail-fields-two">
            <div>
              <label>Address</label>
              <p>{userDetail.address}</p>
            </div>
          </div>
        </div>
      <div className="detail-buttons" >
          <CustomButton
            bkColor={PreparedColor}
            color="white"
            padding="9px"
            width="50%"
            type="button"
            title="PREPARED ORDER"
            clicked={() => setVisible(true)}
          />
      </div>
      </UserDetailContainer>
    </SideBar>
  );
};

export default Index;
