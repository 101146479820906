import styled from "styled-components";
import { PrimaryColor } from "../../../CommonComponents/Global";

export const CollectedContainer = styled.div `
background-color: white !important;
`;
export const UserDetailContainer = styled.div`
    height: 85vh;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
  .userDetail-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    

    @media screen and (max-width : 768px) {
      grid-template-columns: 1fr;
      gap: 10px;
    }

    &-one,
    &-two,
    &-three {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        color: #1b283f;
        font-weight: 600;
      }

      p {
        border-bottom: 1px solid #1b283f;
        font-weight: normal;
      }
    }
  }
  .detail-buttons{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 25%;
  }
`;

export const SunshineModalContent = styled.div`
text-align: center;
  h1 {
    font-size: 16px;
    color: ${PrimaryColor};
    margin: 10px auto;
  }
  p{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: #1B283F;
    font-size: 16px;
    line-height: 22px;
  }

  .modal-content-variable {
    color: ${PrimaryColor};
    font-size: 16px;
    max-width: 60px;
  }
  .sunshine-modal-button{
    margin-top: 15px;
  }
`;