import React, { useEffect, useState } from "react";
import GenerecService from "../../../services/GenericService";
import { API_URL } from "../../../services/config";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SideBar from "../../SideBar/Index";
import ConfirmationModal from "../../../Components/ConfirmationModal/Index";
import { SunshineModalContent , CollectedContainer } from "./style";
import CustomButton from "../../../CommonComponents/Button";
import {
  CollectColor,
  DefaultColor,
  FlexRowContainer,
} from "../../../CommonComponents/Global";
import MobileTable from "./MobileTable";
import LastConfirmationModal from "../../../Components/ConfirmationModal/LastConfirmationModal";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Email",
    dataIndex: "emailAddress",
    key: "emailAddress",
  },
  {
    title: "Phone",
    dataIndex: "cellNumber",
    key: "cellNumber",
  },
  {
    title: "Pickup Request Time",
    dataIndex: "request",
    key: "request",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const Pending = ({ pending }) => {
  const genericService = new GenerecService();

  const [pendingData, setPendingData] = useState([]);
  const [userDetail, setuserDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);

  useEffect(() => {
    setPendingData(pending);
  }, [pending]);

  useEffect(() => {
    genericService
      .get(`${API_URL}getallcollectedorders`)
      .then((msg) => {
        setPendingData(msg?.data);
      })
      .catch((error) => {
        console.log(error, "error in api call");
      });
  }, []);

  function preparedOrderHandler(userDetail) {
    const data = {
      id: userDetail.id,
      cellNumber: userDetail.cellNumber,
      status: 6,
    };

    genericService
      .post(`${API_URL}updateorder`, data)
      .then((msg) => {
        const filteredOrders = pendingData.filter(
          (order) => order.id !== userDetail.id
        );
        setPendingData(filteredOrders);
        setconfirmModal(true)
        setVisible(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const prepareOrder = (user) => {
    setuserDetail(user);
    setVisible(true);
  };

  const myUsers = pendingData?.map((user) => {
    return {
      id: user.id,
      fullName: (
        <Link className="userTableLink" to={`/collect-userdetail/${user.id}`}>
          {user.fullName}
        </Link>
      ),
      emailAddress: user.emailAddress,
      cellNumber: user.cellNumber,
      request: `${user.orderDate} ${user.orderTime}`,
      status: "Collect Order",
      action: (
        <div className="orderTableButton">
          <CustomButton
            bkColor={CollectColor}
            color="white"
            padding="2px"
            width="100%"
            type="button"
            title="PREPARED"
            clicked={() => prepareOrder(user)}
          />
        </div>
      ),
    };
  });

  return (
    <SideBar>
      <div className="d-md-none">
        <MobileTable pendingData={myUsers} handler={prepareOrder} />
      </div>
      <div className="d-none d-md-block">
        <LastConfirmationModal
          visible={confirmModal}
          setVisible={setconfirmModal}
        >
          <SunshineModalContent>
            <h1>Update Successfully</h1>
            {/* <p>
              {" "}
              Order is updated
              <span className="modal-content-variable">
                {" "}
                {userDetail.fullName}
              </span>{" "}
              Status to{" "}
              <span className="modal-content-variable">PREPARED ORDER</span>
            </p> */}
              <p> Your order
                <span className="modal-content-variable">{ ' '} {userDetail.fullName}</span> Status Update Successfully
              </p>
          </SunshineModalContent>
        </LastConfirmationModal>
        <ConfirmationModal visible={visible} setVisible={setVisible}>
          <SunshineModalContent>
            <h1>CONFIRMATION UPDATE STATUS</h1>
            <p>
              You want to update{" "}
              <span className="modal-content-variable">
                {userDetail.fullName}
              </span>{" "}
              Order Status to{" "}
              <span className="modal-content-variable">Prepared Order</span>
            </p>
            <div className="sunshine-modal-button">
              <FlexRowContainer>
                <CustomButton
                  bkColor={CollectColor}
                  color="white"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Yes"
                  clicked={() => preparedOrderHandler(userDetail)}
                />
                <CustomButton
                  bkColor={DefaultColor}
                  color="black"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Cancel"
                  clicked={() => setVisible(false)}
                />
              </FlexRowContainer>
            </div>
          </SunshineModalContent>
        </ConfirmationModal>
        <CollectedContainer>
        <Table
          className="registerTableDetail"
          dataSource={myUsers}
          columns={columns}
          pagination={true}
        />
        </CollectedContainer>
 
      </div>
    </SideBar>
  );
};

export default Pending;
