import { Layout, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import ic_logo from "../../Assets/ic_logo.png";
import ic_notification from "../../Assets/ic_notification.svg";
import ic_search from "../../Assets/ic_search.svg";
import ic_us_flag from "../../Assets/ic_us_flag.svg";
import { SideBarContainerMain } from "./style";
import { Link, useNavigate } from 'react-router-dom';
import Navbar from "../../Components/Navbar/Navbar";
import Drawer from '../../Components/Drawer/Drawer'

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;



function Index({ children }) {
  const navigate = useNavigate();



  const logoutHandler = () => {
    navigate('/')
    localStorage.removeItem('authToken')
  }



  return (
    <>
    <div className="d-lg-none">
       <Navbar/>
       <Drawer/>
      </div>
      <div>
        <SideBarContainerMain>
          <Layout className="main-sidebar-container">
            <Sider
              style={{
                left: 0,
                zIndex: 1,
              }}
              className="sidebar-menu-logo-background d-none d-lg-block"
              breakpoint="lg"
              collapsedWidth="0"
            >
              <div className="main-sidebar-container-logo">
                <img src={ic_logo} alt="ic_logo" />
              </div>
              <Menu 
                theme="dark"
               
                mode="inline"
                defaultSelectedKeys={["/new-order"]}
                selectedKeys={[window.location.pathname]}
                defaultOpenKeys={["/new-order"]}
                style={{ height: "100vh", borderRight: 0 }}
              >
                <Menu.Item key="/new-order">   <Link to="/new-order" />New Order</Menu.Item>
                <Menu.Item key="/accept-order">   <Link to="/accept-order" />Accept Order</Menu.Item>
                <Menu.Item key="/ontheway-order">   <Link to="/ontheway-order" />On The Way</Menu.Item>
                <Menu.Item key="/arrieved-order">   <Link to="/arrieved-order" />Arrieved Order</Menu.Item>
                <Menu.Item key="/collect-order">   <Link to="/collect-order" />Collect Order</Menu.Item>
                <Menu.Item key="/prepared-order">   <Link to="/prepared-order" />Prepared Order</Menu.Item>
                <Menu.Item key="/delivered-order">   <Link to="/delivered-order" />Delivered Order</Menu.Item>
                <Menu.Item key="/declined-order">   <Link to="/declined-order" />Cancel/Declined Order</Menu.Item>
              </Menu>
            </Sider>
            <Layout>
              <Header 
                className="d-none d-lg-block site-layout-sub-header-background"
                style={{ padding: 0 }}
              >
                <div className="main-sidebar-container-main-header d-none d-md-block">
                  <div className="main-sidebar-container-main-header-icons">
                    <img src={ic_search} alt="ic_search" />
                    <img src={ic_notification} alt="ic_notification" />
                    <img src={ic_us_flag} alt="ic_us_flag" />
                    <p onClick={() => logoutHandler() } >Log out</p>
                  </div>
                </div>
              </Header>
              <Content style={{ margin: "24px 16px 0" }}>
                <div className="site-layout-background">{children}</div>
              </Content>
            </Layout>
          </Layout>
        </SideBarContainerMain>

      </div>
      
    </>
  );
}

export default Index;
