import { Drawer, Button, Space } from 'antd';
import React, { useContext } from 'react';
import { DrawerStyledMain } from './StyledDrawer';
import { CollapsedContext } from '../../App';
import { Link } from 'react-router-dom';
import ic_logo from "../../Assets/ic_logo.png";


const MobileSiderBar = () => {
    const { collapse, menuCollapsed } = useContext(CollapsedContext);

    const [placement, setPlacement] = React.useState('left');



    const onClose = () => {
        menuCollapsed(false);
    };


    return (
        <DrawerStyledMain>
            <Drawer
                width={290}
                placement={placement}
                closable={false}
                onClose={onClose}
                visible={collapse}
                key={placement}
               
            >
               <div className="logo-section text-center">
                   <img src={ic_logo} alt="logo" />
                   </div>              
                            
                <div className='drawer-menus mt-3'>
                    <div className="d-flex justify-content-between align-items-center drawer-menu">
                        <Link to="/new-order"> New Order</Link>
                        <p>&#x203A;</p>
                       
                    </div>
                    <div className="d-flex justify-content-between align-items-center drawer-menu">
                        <Link to="/accept-order">Accept Order</Link>
                        <p>&#x203A;</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center drawer-menu">
                        <Link to="/collect-order">Collect Order</Link>
                        <p>&#x203A;</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center drawer-menu">
                        <Link to="/prepared-order">Prepared Order</Link>
                        <p>&#x203A;</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center drawer-menu">
                        <Link to="/delivered-order">Delivered Order</Link>
                        <p>&#x203A;</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center drawer-menu">
                        <Link to="/declined-order">Declined Order</Link>
                        <p>&#x203A;</p>
                    </div>

                </div>
            </Drawer>
        </DrawerStyledMain>

    );
};

export default MobileSiderBar;