import styled from "styled-components";

export const NewOrderConfirmationModal = styled.div`
  .modal-logo {
    text-align: center !important;
    background-color: red !important;
  }
`;

export const CenterDivImg = styled.div`
  text-align: center;
`;