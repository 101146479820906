import React from "react";
import { MobileTableStyledMain } from "./StyledMobileTable";
import {
  DangerColor,
  SuccessColor,
  NoDataFound,
} from "../../../CommonComponents/Global";
import CustomButton from "../../../CommonComponents/Button";
import {Link} from 'react-router-dom';

const MobileTable = ({
  pendingData,
  cancelOrderHandler,
  acceptOrderHandler,
}) => {
  return (
    <div>
      {!pendingData?.length ? (
        <NoDataFound>No Data Found</NoDataFound>
      ) : (
        <MobileTableStyledMain>
          <div className="inner-section container">
            {pendingData?.map((user, index) => (
              <div key={index} className="card">
                <div className="name-section d-flex justify-content-between">
                  <Link
                    className="userTableLink"
                    to={`/pending-userdetail/${user.id}`}
                  >
                    {user.fullName}
                  </Link>
                  <p className="id">{user.cellNumber}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="details">{user.emailAddress}</p>
                  <p className="details-1">
                    {user.orderDate} {user.orderTime}
                  </p>
                </div>
                <div className="actions-section d-flex  justify-content-between gap-2">
                  <CustomButton
                    bkColor={DangerColor}
                    color="white"
                    padding="2px"
                    width="100%"
                    type="button"
                    title="Cancel"
                    clicked={() => cancelOrderHandler(user)}
                  />
                  <CustomButton
                    bkColor={SuccessColor}
                    color="white"
                    padding="2px"
                    width="100%"
                    type="button"
                    title="Accept"
                    clicked={() => acceptOrderHandler(user)}
                  />
                </div>
              </div>
            ))}
          </div>
        </MobileTableStyledMain>
      )}
    </div>
  );
};

export default MobileTable;
