import React from "react";
import { Modal } from "antd";
import { NewOrderConfirmationModal , CenterDivImg } from "./style";
import ic_logo from "../../Assets/ic_logo.png";

const ConfirmationModal = ({ visible, setVisible, children }) => {
  return (
    <NewOrderConfirmationModal>
      <Modal
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={400}
        footer={false}
      >
        <CenterDivImg>
          <img src={ic_logo} alt="ic_logo" />
        </CenterDivImg>
        {children}
      </Modal>
    </NewOrderConfirmationModal>
  );
};

export default ConfirmationModal;
