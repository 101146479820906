import styled from "styled-components";

export const StyledNavbar = styled.div`
  .navbar-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #156985;
    padding: 10px;
    font-size: 20px;
    color: white;
    .menu-icon {
      cursor: pointer;
    }
    p {
      margin: 0;
    }
    .logout-menu {
      display: flex;
      .item {
        margin-left: 10px;
      }
      .logout-text {
        margin-right: 10px;
      }
      p{
          font-size: 15px;
          margin-left: 10px;
      }
    }
  }
`;
