import React, { useState, useEffect, createContext } from "react";
import "./App.css";
import Login from "./DashboardLayout/Login/Index";
import { Routes, Route, useLocation } from "react-router-dom";
import GenericService from "./services/GenericService";
import { API_URL } from "./services/config";
import { Base64 } from "js-base64";
import { ToastContainer } from "react-toastify";
import Pending from "./DashboardLayout/UserTable/pending/index";
import PendingUserDetail from "./DashboardLayout/UserTable/pending/UserDetail";
import Collected from "./DashboardLayout/UserTable/collected/index";
import CollectedUserDetail from "./DashboardLayout/UserTable/collected/UserDetail";
import AcceptOrder from "./DashboardLayout/UserTable/AcceptOrder/Index";
import AcceptOrderUserDetail from "./DashboardLayout/UserTable/AcceptOrder/UserDetail";
import PreparedOrder from "./DashboardLayout/UserTable/PreparedOrder/Index";
import PreparedOrderUserDetail from "./DashboardLayout/UserTable/PreparedOrder/UserDetail";
import DeliverdOrder from "./DashboardLayout/UserTable/DeliverdOrder/Index";
import DeclinedOrder from "./DashboardLayout/UserTable/DeclinedOrder/Index";
import UserDetail from "./DashboardLayout/UserDetail/Index";
import ArrievedOrder from "./DashboardLayout/UserTable/ArrievedOrder/Index";
import ArrievedOrderUserDetail from "./DashboardLayout/UserTable/ArrievedOrder/UserDetail";
import OnTheWay from './DashboardLayout/UserTable/onTheWay/index'
import OnTheWayUserDetail from './DashboardLayout/UserTable/onTheWay/UserDetail'
export const CollapsedContext = createContext();

function App() {
  const [collapse, setCollapse] = useState(false);
  const location = useLocation();
  const genericService = new GenericService();
  const urlId = location.pathname.split("/")[2];
  const menuCollapsed = (data) => {
    setCollapse(data);
  };

  const base64ToStringNew = urlId ? Base64?.decode(urlId) : "";
  const stringToNumber = parseInt(base64ToStringNew);

  const path = location.pathname.split("/")[1];

  const [userDetailForm, setuserDetailForm] = useState({});

  useEffect(() => {
    if (path === "clientIntakeInterview") {
      genericService
        .get(`${API_URL}admin/getusersbyid/${stringToNumber}`)
        .then((msg) => {
          const data = msg.data;
          setuserDetailForm({ ...userDetailForm, ...data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <CollapsedContext.Provider value={{ menuCollapsed, collapse }}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="new-order" element={<Pending />} />
        <Route path="pending-userdetail/:id" element={<PendingUserDetail />} />
        <Route path="accept-order" element={<AcceptOrder />} />
        <Route
          path="accept-userdetail/:id"
          element={<AcceptOrderUserDetail />}
        />
        <Route path="ontheway-order" element={<OnTheWay />} />
        <Route
          path="ontheway-userdetail/:id"
          element={<OnTheWayUserDetail />}
        />
        <Route path="collect-order" element={<Collected />} />
        <Route
          path="collect-userdetail/:id"
          element={<CollectedUserDetail />}
        />
        <Route path="prepared-order" element={<PreparedOrder />} />
        <Route path="prepared-userdetail/:id" element={<PreparedOrderUserDetail />} />
        <Route path="delivered-order" element={<DeliverdOrder />} />
        <Route path="arrieved-order" element={<ArrievedOrder />} />
        <Route path="arrieved-userdetail/:id" element={<ArrievedOrderUserDetail />} />
        <Route path="declined-order" element={<DeclinedOrder />} />
        <Route path="userDetail/:id" element={<UserDetail />} />
      </Routes>
    </CollapsedContext.Provider>
  );
}

export default App;
