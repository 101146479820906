import { MenuFoldOutlined } from '@ant-design/icons'
import React, { useContext } from 'react'
import { CollapsedContext } from '../../App';
import { StyledNavbar } from './StyledNavbar'
import { useNavigate} from 'react-router-dom';
import ic_notification from "../../Assets/ic_notification.svg";
import ic_search from "../../Assets/ic_search.svg";

const Navbar = () => {
    const navigate = useNavigate();
    const navigateHandler = () => {
      navigate(`/clientIntakeInterview/MzU=`)
    }

    const logoutHandler = () => {
      navigate('/')
      localStorage.removeItem('authToken')
    }

    
const {menuCollapsed}=useContext(CollapsedContext);
  return (
    <StyledNavbar>
        <div className="navbar-main">
        <div className="menu-icon">
         <MenuFoldOutlined  onClick={()=>menuCollapsed(true)} />
            </div>    
            <div className="logout-menu">
                <img className='item' onClick={() => navigateHandler()}  src={ic_search} alt="ic_search" />
                <img className='item' src={ic_notification} alt="ic_notification" />
                <p onClick={() => logoutHandler() } >Log out</p>
              </div>

        </div>
    </StyledNavbar>
  )
}

export default Navbar