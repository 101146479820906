import React from "react";
import { MobileTableStyledMain } from "./StyledMobileTable";
import { CollectColor } from "../../../CommonComponents/Global";
import CustomButton from "../../../CommonComponents/Button";

const MobileTable = ({ pendingData }) => {
  return (
    <MobileTableStyledMain>
      <div className="inner-section container">
        {pendingData?.map((user, index) => (
          <div className="card">
            <div className="name-section d-flex justify-content-between">
              <p className="name">{user.fullName}</p>
              <p className="id">{user.cellNumber}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="details">{user.emailAddress}</p>
              <p className="details-1">{user.request}</p>
            </div>
            <div className="price-section d-flex justify-content-between">
              <p className="price">{user.id}</p>
              <p className="date">{user.status}</p>
            </div>
          </div>
        ))}
      </div>
    </MobileTableStyledMain>
  );
};

export default MobileTable;
