import styled from "styled-components";

export const ArrievedOrderContainer = styled.div`
    background-color: white !important;
`

export const UserDetailContainer = styled.div`
    height: 85vh;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
  .userDetail-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    

    @media screen and (max-width : 768px) {
      grid-template-columns: 1fr;
      gap: 10px;
    }

    &-one,
    &-two,
    &-three {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        color: #1b283f;
        font-weight: 600;
      }

      p {
        border-bottom: 1px solid #1b283f;
        font-weight: normal;
      }
    }
  }
  .detail-buttons{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 25%;
  }
`;