import React, { useEffect, useState } from "react";
import GenerecService from "../../../services/GenericService";
import { API_URL } from "../../../services/config";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SideBar from "../../SideBar/Index";
import CustomButton from "../../../CommonComponents/Button";
import {
  DangerColor,
  DefaultColor,
  FlexRowContainer,
  PrimaryColor,
  SuccessColor,
} from "../../../CommonComponents/Global";
import { OrderTableContainer, SunshineModalContent } from "./style";
import MobileTable from "./MobileTable";
import ConfirmationModal from "../../../Components/ConfirmationModal/Index";
import LastConfirmationModal from "../../../Components/ConfirmationModal/LastConfirmationModal";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Email",
    dataIndex: "emailAddress",
    key: "emailAddress",
  },
  {
    title: "Phone",
    dataIndex: "cellNumber",
    key: "cellNumber",
  },
  {
    title: "Pickup Request Time",
    dataIndex: "request",
    key: "request",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const Pending = ({ pending }) => {
  const genericService = new GenerecService();

  const [pendingData, setPendingData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false)
  const [userDetail, setUserDetail] = useState("");
  const [userStatus, setUserStatus] = useState("");

  useEffect(() => {
    setPendingData(pending);
  }, [pending]);

  useEffect(() => {
    genericService
      .get(`${API_URL}getallpendingorders`)
      .then((msg) => {
        // console.log(msg, "message");
        setPendingData(msg?.data);
      })
      .catch((error) => {
        console.log(error, "error in api call");
      });
  }, []);

  function acceptOrderHandler(user) {
    const data = {
      id: user.id,
      cellNumber: user.cellNumber,
      status: 2,
    };

    genericService
      .post(`${API_URL}updateorder`, data)
      .then((msg) => {
        const filteredOrders = pendingData.filter(
          (order) => order.id !== user.id
        );
        setPendingData(filteredOrders);
        setVisible(false);
        setconfirmModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function cancelOrderHandler(user) {
    const data = {
      id: user.id,
      cellNumber: user.cellNumber,
      status: 8,
    };

    genericService
      .post(`${API_URL}updateorder`, data)
      .then((msg) => {
        const filteredOrders = pendingData.filter(
          (order) => order.id !== user.id
        );
        setPendingData(filteredOrders);
        setVisible(false);
        setconfirmModal(true)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const canceledOrder = (user) => {
    setUserDetail(user);
    setVisible(true);
    setUserStatus("Cancel");
  };
  const acceptOrder = (user) => {
    setUserDetail(user);
    setVisible(true);
    setUserStatus("Accept");
  };

  const myUsers = pendingData?.map((user) => {
    return {
      id: user.id,
      fullName: (
        <Link className="userTableLink" to={`/pending-userdetail/${user.id}`}>
          {user.fullName}
        </Link>
      ),
      emailAddress: user.emailAddress,
      cellNumber: user.cellNumber,
      request: `${user.orderDate} ${user.orderTime}`,
      action: (
        <div className="orderTableButton">
          <CustomButton
            bkColor={DangerColor}
            color="white"
            padding="2px"
            width="100%"
            type="button"
            title="Cancel"
            clicked={() => canceledOrder(user)}
          />
          <CustomButton
            bkColor={SuccessColor}
            color="white"
            padding="2px"
            width="100%"
            type="button"
            title="Accept"
            clicked={() => acceptOrder(user)}
          />
        </div>
      ),
    };
  });

  return (
    <SideBar>
      <div className="d-md-none">
        <MobileTable
          pendingData={pendingData}
          acceptOrderHandler={acceptOrder}
          cancelOrderHandler={canceledOrder}
        />
      </div>
      <div className="d-none d-md-block">
        <OrderTableContainer>
          <LastConfirmationModal visible={confirmModal} setVisible={setconfirmModal} >
          <SunshineModalContent>
              <h1>Update Successfully</h1>
              {/* <p> Order is updated
                <span className="modal-content-variable">{ ' '} {userDetail.fullName}</span> Status to { ' '}
                <span className="modal-content-variable">
                  {userStatus == "Accept" ? "Accept" : "Cancel"}
                </span>
              </p> */}
                      <p> Your order
                <span className="modal-content-variable">{ ' '} {userDetail.fullName}</span> Status Update Successfully
              </p>
            </SunshineModalContent>
          </LastConfirmationModal>
          <ConfirmationModal visible={visible} setVisible={setVisible}>
            <SunshineModalContent>
              <h1>CONFIRMATION UPDATE STATUS</h1>
              <p>
                You want to update{" "}
                <span className="modal-content-variable">
                  {userDetail.fullName}
                </span>{" "}
                Order Status to{" "}
                <span className="modal-content-variable">
                  {userStatus == "Accept" ? "Accept" : "Cancel"}{" "}
                </span>
              </p>
              <div className="sunshine-modal-button">
                <FlexRowContainer>
                  <CustomButton
                    bkColor={PrimaryColor}
                    color="white"
                    padding="7px"
                    width="100%"
                    type="button"
                    title="Yes"
                    clicked={
                      userStatus == "Accept"
                        ? () => acceptOrderHandler(userDetail)
                        : () => cancelOrderHandler(userDetail)
                    }
                  />
                  <CustomButton
                    bkColor={DefaultColor}
                    color="black"
                    padding="7px"
                    width="100%"
                    type="button"
                    title="Cancel"
                    clicked={() => setVisible(false)}
                  />
                </FlexRowContainer>
              </div>
            </SunshineModalContent>
          </ConfirmationModal>
          <Table
            className="registerTableDetail"
            dataSource={myUsers}
            columns={columns}
            pagination={true}
          />
        </OrderTableContainer>
      </div>
    </SideBar>
  );
};

export default Pending;
