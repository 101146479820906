import React, { useEffect, useState } from "react";
import GenerecService from "../../../services/GenericService";
import { API_URL } from "../../../services/config";
import { Table } from "antd";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SideBar from "../../SideBar/Index";
import CustomButton from "../../../CommonComponents/Button";
import { CollectColor, FlexRowContainer, DefaultColor } from "../../../CommonComponents/Global";
import { PreparedOrderContainer } from "./Style";
import MobileTable from "./MobileTable";
import ConfirmationModal from "../../../Components/ConfirmationModal/Index";
import { SunshineModalContent } from "../pending/style";
import LastConfirmationModal from "../../../Components/ConfirmationModal/LastConfirmationModal";




const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Email",
    dataIndex: "emailAddress",
    key: "emailAddress",
  },
  {
    title: "Phone",
    dataIndex: "cellNumber",
    key: "cellNumber",
  },
  {
    title: "Pickup Request Time",
    dataIndex: "request",
    key: "request",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const Pending = ({ pending }) => {
  const genericService = new GenerecService();

  const [pendingData, setPendingData] = useState([]);
  const [userDetail, setuserDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);


  useEffect(() => {
    setPendingData(pending);
  }, [pending]);

  useEffect(() => {
    genericService
      .get(`${API_URL}getallpreparedorders`)
      .then((msg) => {
        setPendingData(msg?.data);
      })
      .catch((error) => {
        console.log(error, "error in api call");
      });
  }, []);

  function preparedOrderHandler(userDetail) {
    const data = {
      id: userDetail.id,
      cellNumber: userDetail.cellNumber,
      status: 7,
    };

    genericService
      .post(`${API_URL}updateorder`, data)
      .then((msg) => {
        const filteredOrders = pendingData.filter(
          (order) => order.id !== userDetail.id
        );
        setVisible(false)
        setPendingData(filteredOrders);
        setconfirmModal(true)
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const collecOrder = (user) => {
    setuserDetail(user)
      setVisible(true)
  }
  const myUsers = pendingData?.map((user) => {
    return {
      id: user.id,
      fullName: (
        <Link className="userTableLink" to={`/prepared-userdetail/${user.id}`}>
          {user.fullName}
        </Link>
      ),
      emailAddress: user.emailAddress,
      cellNumber: user.cellNumber,
      request: `${user.orderDate} ${user.orderTime}`,
      status : 'Accept Order',
      action: (
        <div className="orderTableButton">
          <CustomButton
            bkColor={CollectColor}
            color="white"
            padding="2px"
            width="100%"
            type="button"
            title="DELIVER"
            clicked={() => collecOrder(user)}
          />
        </div>
      )
    };
  });


  return (
    <SideBar>
      <div className="d-md-none">
        <MobileTable pendingData={myUsers} handler={collecOrder}/>
      </div>
      <div className="d-none d-md-block">
      <PreparedOrderContainer>
      <LastConfirmationModal
          visible={confirmModal}
          setVisible={setconfirmModal}
        >
          <SunshineModalContent>
            <h1>Update Successfully</h1>
            <p>
              {" "}
              Order is updated
              <span className="modal-content-variable">
                {" "}
                {userDetail.fullName}
              </span>{" "}
              Status to{" "}
              <span className="modal-content-variable">DELIVER ORDER</span>
            </p>
          </SunshineModalContent>
        </LastConfirmationModal>
      <ConfirmationModal visible={visible} setVisible={setVisible}>
          <SunshineModalContent>
            <h1>CONFIRMATION UPDATE STATUS</h1>
            <p>
              You want to update{" "}
              <span className="modal-content-variable">
                {userDetail.fullName}
              </span>{" "}
              Order Status to{" "}
              <span className="modal-content-variable">PREPARED ORDER</span>
            </p>
            <div className="sunshine-modal-button">
              <FlexRowContainer>
                <CustomButton
                  bkColor={CollectColor}
                  color="white"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Yes"
                  clicked={() => preparedOrderHandler(userDetail)}
                />
                <CustomButton
                  bkColor={DefaultColor}
                  color="black"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Cancel"
                  clicked={() => setVisible(false)}
                />
              </FlexRowContainer>
            </div>
          </SunshineModalContent>
        </ConfirmationModal>
        <Table
          className="registerTableDetail"
          dataSource={myUsers}
          columns={columns}
          pagination={true}
          />
      </PreparedOrderContainer>
          </div>
    </SideBar>
  );
};

export default Pending;
