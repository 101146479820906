import React, { useEffect, useState } from "react";
import GenerecService from "../../../services/GenericService";
import { API_URL } from "../../../services/config";
import { Table } from "antd";
import { Link } from "react-router-dom";
import SideBar from "../../SideBar/Index";
import { DeclinedOrderContainer } from "./Style";
import { DangerColor, FinalMessage } from "../../../CommonComponents/Global";
import MobileTable from "./MobileTable";


const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Email",
    dataIndex: "emailAddress",
    key: "emailAddress",
  },
  {
    title: "Phone",
    dataIndex: "cellNumber",
    key: "cellNumber",
  },
  {
    title: "Pickup Request Time",
    dataIndex: "request",
    key: "request",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const Pending = ({ pending }) => {
  const genericService = new GenerecService();

  const [pendingData, setPendingData] = useState([]);

  useEffect(() => {
    setPendingData(pending);
  }, [pending]);

  useEffect(() => {
    genericService
      .get(`${API_URL}getallcancelleddorders`)
      .then((msg) => {
        setPendingData(msg?.data);
      })
      .catch((error) => {
        console.log(error, "error in api call");
      });
  }, []);

  const myUsers = pendingData?.map((user) => {
    return {
      id: user.id,
      fullName: (
        <Link className="userTableLink" to={`/userDetail/${user.id}`}>
          {user.fullName}
        </Link>
      ),
      emailAddress: user.emailAddress,
      cellNumber: user.cellNumber,
      request: `${user.orderDate} ${user.orderTime}`,
      status: <FinalMessage color={DangerColor} >canceled order</FinalMessage>,
    };
  });

  
  return (
    <SideBar>
        <div className="d-md-none">
        <MobileTable pendingData={myUsers}/>
      </div>
      <div className="d-none d-md-block">

      <DeclinedOrderContainer>
        <Table
          className="registerTableDetail"
          dataSource={myUsers}
          columns={columns}
          pagination={true}
          />
      </DeclinedOrderContainer>
          </div>
    </SideBar>
  );
};

export default Pending;
