import React, { useEffect, useState } from "react";
import SideBar from "../SideBar/Index";
import { UserDetailContainer } from "./Style";
import { useParams } from "react-router-dom";
import GenericService from "../../services/GenericService";
import { API_URL } from "../../services/config";

const Index = () => {
  const { id } = useParams();
  const genericService = new GenericService();

  const [userDetail, setuserDetail] = useState({});

  useEffect(() => {
    const data = {
      id: id,
    };
    genericService
      .post(`${API_URL}getuserdata`, data)
      .then((msg) => {
        console.log(msg, "msgmsg");
        setuserDetail(msg?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <SideBar>
      <UserDetailContainer>
        <div className="userDetail-fields">
          <div className="userDetail-fields-one">
            <div>
              <label>First Name</label>
              <p>{userDetail.fullName}</p>
            </div>

            <div>
              <label>Phone</label>
              <p>{userDetail.cellNumber}</p>
            </div>
          </div>
          <div className="userDetail-fields-two">
            <div>
              <label>Email</label>
              <p>{userDetail.emailAddress}</p>
            </div>

            <div>
              <label>Pickup Request Time</label>
              <p>{userDetail.orderTime} , {userDetail.orderDate}</p>
            </div>
          </div>
          <div className="userDetail-fields-two">
            <div>
              <label>Address</label>
              <p>{userDetail.address}</p>
            </div>
          </div>
        </div>
     
      </UserDetailContainer>
    </SideBar>
  );
};

export default Index;
