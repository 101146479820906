import React, { useEffect, useState } from "react";
import SideBar from "../../../DashboardLayout/SideBar/Index";
import { UserDetailContainer , SunshineModalContent } from "./style";
import { useParams } from "react-router-dom";
import GenericService from "../../../services/GenericService";
import { API_URL } from "../../../services/config";
import { DangerColor , DefaultColor, FlexRowContainer, PrimaryColor, SuccessColor } from "../../../CommonComponents/Global";
import CustomButton from "../../../CommonComponents/Button";
import { useNavigate} from 'react-router-dom';
import ConfirmationModal from "../../../Components/ConfirmationModal/Index";
import LastConfirmationModal from "../../../Components/ConfirmationModal/LastConfirmationModal";

const Index = () => {
  const { id } = useParams();
  const genericService = new GenericService();
  const navigate = useNavigate();


  const [userDetail, setuserDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [userStatus , setUserStatus] = useState('');
  const [confirmModal, setconfirmModal] = useState(false)


  useEffect(() => {
    const data = {
      id: id,
    };
    genericService
      .post(`${API_URL}getuserdata`, data)
      .then((msg) => {
        console.log(msg, "msgmsg");
        setuserDetail(msg?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);


  function acceptOrderHandler(user) {
    const data = {
      id: user.id,
      cellNumber: user.cellNumber,
      status: 2,
    };

    genericService
      .post(`${API_URL}updateorder`, data)
      .then((msg) => {
       
        setVisible(false)
        setconfirmModal(true)
        setTimeout(() => {
          navigate('/new-order')
        },2000)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function cancelOrderHandler(user) {
    const data = {
      id: user.id,
      cellNumber: user.cellNumber,
      status: 8,
    };

    genericService
      .post(`${API_URL}updateorder`, data)
      .then((msg) => {
        setVisible(false)
        setconfirmModal(true)
        setTimeout(() => {
          navigate('/new-order')
        },2000)
      })
      .catch((error) => {
        console.log(error);
      });
  }




  return (
    <SideBar>
      <UserDetailContainer>
      <LastConfirmationModal visible={confirmModal} setVisible={setconfirmModal} >
          <SunshineModalContent>
              <h1>Update Successfully</h1>
              {/* <p> Order is updated
                <span className="modal-content-variable">{ ' '} {userDetail.fullName}</span> Status to { ' '}
                <span className="modal-content-variable">
                  {userStatus == "Accept" ? "Accept" : "Cancel"}
                </span>
              </p> */}
                <p> Your order
                <span className="modal-content-variable">{ ' '} {userDetail.fullName}</span> Status Update Successfully
              </p>
            </SunshineModalContent>
          </LastConfirmationModal>
      <ConfirmationModal visible={visible} setVisible={setVisible}>
            <SunshineModalContent>
              <h1>CONFIRMATION UPDATE STATUS</h1>
              <p>
                You want to update{" "}
                <span className="modal-content-variable">{userDetail.fullName}</span>{" "}
                Order Status to{" "}
                <span className="modal-content-variable">{userStatus == 'Accept' ?  'Accept' : 'Cancel'} </span>
              </p>
              <div className="sunshine-modal-button" >
              <FlexRowContainer>
                <CustomButton
                  bkColor={PrimaryColor}
                  color="white"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Yes"
                  clicked={userStatus == 'Accept' ?  () => acceptOrderHandler(userDetail) : () => cancelOrderHandler(userDetail)}
                />
                <CustomButton
                  bkColor={DefaultColor}
                  color="black"
                  padding="7px"
                  width="100%"
                  type="button"
                  title="Cancel"
                  clicked={() => setVisible(false)}
                />
              </FlexRowContainer>
              </div>
            </SunshineModalContent>
          </ConfirmationModal>
        <div className="userDetail-fields">
          <div className="userDetail-fields-one">
            <div>
              <label>First Name</label>
              <p>{userDetail.fullName}</p>
            </div>

            <div>
              <label>Phone</label>
              <p>{userDetail.cellNumber}</p>
            </div>
          </div>
          <div className="userDetail-fields-two">
            <div>
              <label>Email</label>
              <p>{userDetail.emailAddress}</p>
            </div>

            <div>
              <label>Pickup Request Time</label>
              <p>{userDetail.orderTime} , {userDetail.orderDate}</p>
            </div>
          </div>
          <div className="userDetail-fields-two">
            <div>
              <label>Address</label>
              <p>{userDetail.address}</p>
            </div>
          </div>
        </div>
      <div className="detail-buttons" >
          <CustomButton
            bkColor={DangerColor}
            color="white"
            padding="9px"
            width="100%"
            type="button"
            title="CANCEL ORDER"
            clicked={() => [setVisible(true) , setUserStatus('Cancel') ]}
          />
          <CustomButton
            bkColor={SuccessColor}
            color="white"
            padding="9px"
            width="100%"
            type="button"
            title="ACCEPT ORDER"
            clicked={() =>  [setVisible(true) , setUserStatus('Accept') ]  }
          />
      </div>
      </UserDetailContainer>
    </SideBar>
  );
};

export default Index;
